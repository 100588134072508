



































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class Register extends Vue {
  errMsg: string = ''
  email: string = ''
  password: string = ''
  firstName: string = ''
  lastName: string = ''

  // validation params
  isPasswordValid: boolean = false
  isModelValid: boolean = false
  hasMinimumLenth: boolean = false
  hasNumber: boolean = false
  hasLowercase: boolean = false
  hasUppercase: boolean = false
  hasSpecial: boolean = false

  formChanged (e: Event) {
    this.errMsg = ''

    // console.log(e)
  }

  created () {
    this.$store.dispatch('logout')
  }

  validateInputs (e: Event) {
    let isValid: boolean = true
    const inputArray = document.getElementsByTagName('input')

    for (let index = 0; index < inputArray.length; index++) {
      const _input = inputArray[index]

      if (!this.validateInput(_input)) {
        isValid = false
      }

      // handle password field
      if (_input.type === 'password') {
        this.validatePassword()

        isValid = this.isPasswordValid
      }
    }
    this.isModelValid = isValid
  }

  validateInput (input:any) {
    return input.validity.valid
  }

  validatePassword () {
    this.hasMinimumLenth = (this.password.length > 8)
    this.hasNumber = /\d/.test(this.password)
    this.hasLowercase = /[a-z]/.test(this.password)
    this.hasUppercase = /[A-Z]/.test(this.password)
    // eslint-disable-next-line no-useless-escape
    this.hasSpecial = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password)
    this.isPasswordValid = this.hasMinimumLenth && this.hasSpecial && this.hasLowercase && this.hasUppercase && this.hasSpecial
  }

  register (e: Event) {
    e.preventDefault()

    const data = {
      email: this.email,
      password: this.password,
      firstName: this.firstName,
      secondName: this.lastName
    }

    axios.post('/register', data, { withCredentials: true })
      .then(resp => {
      })
      .catch(err => {
        this.errMsg = `Failed to register:${err.response ? `<br><small>${err.response.data.message}</small>` : ''}`
      })
  }
}



























import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import { EventBus } from '@/utils/event-bus'

@Component
export default class Login extends Vue {
  errMsg: string = ''
  username: string = ''
  password: string = ''

  formChanged () {
    this.errMsg = ''
  }

  created () {
    this.$store.dispatch('logout')
  }

  login (e: Event) {
    e.preventDefault()
    const email = this.username
    const password = this.password
    const data = {
      email: email,
      password: password
    }

    axios.post('/login', data, { withCredentials: true })
      .then(resp => {
        // console.log('resp', resp)
        this.$store.dispatch('setUserIsNonSSO')
        this.$store.dispatch('loginSuccess', resp.data).then(() => {
          EventBus.$emit('market-selected')
          this.$store.dispatch('loadProducts', this.$store.getters.getSelectedMarket).then(() => {
            const _redirect = this.$route.query.redirect as string || '/'
            this.$router.push(_redirect)
          })
        })
      })
      .catch(err => {
        this.errMsg = `Failed to login:${err.response ? `<br><small>${err.response.data.message}</small>` : ''}`

        this.$store.dispatch('loginFailed')
      })
  }
}






























import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class SetPassword extends Vue {
  password: string = ''
  msg = ''
  hasMinimumLength: boolean = false
  hasNumber: boolean = false
  hasLowercase: boolean = false
  hasUppercase: boolean = false
  hasSpecial: boolean = false
  isPasswordValid: boolean = false
  email: string = this.$router.currentRoute.params.email
  token: string = this.$router.currentRoute.params.token

  validatePassword () {
    this.hasMinimumLength = (this.password.length > 8)
    this.hasNumber = /\d/.test(this.password)
    this.hasLowercase = /[a-z]/.test(this.password)
    this.hasUppercase = /[A-Z]/.test(this.password)
    // eslint-disable-next-line no-useless-escape
    this.hasSpecial = /[!@#\$%\^\&*\)\(+=._-]/.test(this.password)
    this.isPasswordValid = this.hasMinimumLength && this.hasNumber && this.hasLowercase && this.hasUppercase && this.hasSpecial
  }

  created () {
    this.$store.dispatch('logout')
  }

  setPassword (e: Event) {
    const data = {
      email: this.email,
      token: this.token,
      password: this.password
    }

    e.preventDefault()
    axios.post('/set-password', data, { withCredentials: true })
      .then(resp => {
        this.msg = resp.data
        setTimeout(() => {
          this.$router.push({ name: 'login' })
        }, 2000)
      })
      .catch(err => {
        this.msg = `Failed to set password:${err.response ? `<br><small>${err.response.data.message}</small>` : ''}`
      })
  }
}

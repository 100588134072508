

























import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class PasswordReset extends Vue {
  errMsg: string = ''
  username: string = ''
  resIsVisible: boolean = false;

  formChanged () {
    this.errMsg = ''
  }

  created () {
    this.$store.dispatch('logout')
  }

  resetPassword (e: Event) {
    e.preventDefault()
    const data = {
      email: this.username
    }

    axios.post('/reset-password', data, { withCredentials: true })
      .then(resp => {
        if (resp.request.status === 200) {
          this.resIsVisible = !this.resIsVisible
        }
      })
      .catch(err => {
        this.errMsg = `Failed to request password reset:${err.response ? `<br><small>${err.response.data.message}</small>` : ''}`
      })
  }
}
